<template>
    <div class="row justify-content-center min-vh-100 align-items-center resetBG">
        <div class="col-12 col-md-6 col-lg-4 sBlock">
            <div class="authincation-content">
                <div class="row no-gutters">
                    <div class="col-xl-12">
                        <div class="auth-form">
                            <div class="text-center mb-3">
                                <a href="/">
                                    <img src="../../assets/images/logo.png" alt="">
                                </a>
                            </div>
                            <h4 class="text-center mb-4">Reset your password</h4>
                            <form @submit.prevent="validateInput" ref="resetPass">
                                <div class="form-group">
                                    <label class="mb-1 text-black"><strong>Email</strong></label>
                                    <input type="email" class="form-control" placeholder="Enter email here" v-model="email">
                                </div>
                                <div class="text-center">
                                    <button type="submit" class="btn btn-primary btn-block">Send reset link</button>
                                </div>
                            </form>
                            <div class="new-account mt-3">
                              <router-link class="btn btn-outline-primary" :to="{name: 'Login'}">Back to login</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import methodsMixin from "../../utils/methodsMixin";

export default {
    name: "ResetPassword",
    data(){
        return {
            email: '',
        }
    },
    mixins: [methodsMixin],
    methods: {
        async proceed(){
            const loader = this.$loading.show({container: this.$refs.resetPass});
            let response = await this.$store.dispatch('auth/resetPassword', this.email);
            loader.hide();
            if(response.status){
                return toastr.success("Password reset link sent!")
            }else{
                console.log(response.message);
                return toastr.error("Unable to complete this operation. Specified email might not exist", 'Error')
            }
        },
        validateInput(){
            if(!this.validateEmail(this.email)){
                return toastr.warning("Enter a valid email address", "Oops!")
            }
            return this.proceed()
        }
    }
}
</script>

<style scoped>
.resetBG{
  background: url('../../assets/images/background/loginBg.jpg') no-repeat center center;
  background-size: cover;
}
.resetBG:after{
  content:"";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.2);
}
.sBlock{
  z-index: 3;
}
.authincation-content{
  background-color: rgba(255,255,255,0.8);
}
</style>